import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col, Alert } from "react-bootstrap";
import styled from "styled-components";
import "../components/layout.scss";
import Video from "../components/Video";
import Layout from "../components/layout";
import questionFlow from "../config/questionFlow";

const StyledContainer = styled(Container)`
  padding-bottom: 40px;
`;

export const query = graphql`
  {
    paymentSuccess : contentfulPaymentSuccess(childContentfulPaymentSuccessMessageTextNode: {}, message: {}) {
      message {
        message
      }
      title
      video {
        file {
          url
        }
      }
      subHeading
      videoPlaceholder {
        fluid {
          src
        }
      }
    }
  }
`
  ;

function PaymentSuccess({ data }) {
  const [error, setError] = useState(null);
  const {
    paymentSuccess: {
      message: { message },
      title,
      subHeading,
      video: { file: { url } },
      videoPlaceholder: { fluid: { src } }
    } } = data;

  return (
    <Layout>
      <div id="content" className="site-content">
        <div className="content-inner">
          <div className="">
            <div className="row content-row">
              <div id="primary" className="content-area col-12">
                <main id="main" className="site-main">
                  <article
                    id="post-45"
                    className="post-45 page type-page status-publish hentry"
                  >
                    <div className="entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="45"
                        className="elementor elementor-45"
                        data-elementor-settings="[]"
                      >
                        <div className="elementor-section-wrap">
                          <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                            data-id="f6893e0"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched"}'
                          >
                            <div className="elementor-container elementor-column-gap-default ">
                              <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f5f77a"
                                data-id="9f5f77a"
                                data-element_type="column"
                              >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-4522805 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="4522805"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-367b21f"
                                        data-id="367b21f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-9265126 cms-element elementor-widget elementor-widget-cms_image_single"
                                            data-id="9265126"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container animation-top">
                                              <div className="cms-image-single cms-animation-left-to-right ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="113"
                                                    height="117"
                                                    src="../images/image-home-2.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8869f2a"
                                        data-id="8869f2a"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                                            data-id="8832270"
                                            data-element_type="widget"
                                            data-widget_type="cms_heading.default"
                                          >
                                            <div className="elementor-widget-container payment-page-widget-container">
                                              <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                                <div className="custom-subheading">
                                                  {subHeading}
                                                </div>
                                                <h3 className="custom-heading">
                                                  <span>{title}</span>
                                                </h3>
                                                <div className="custom-heading-description mb-4">
                                                  {message}
                                                </div>

                                                <Video
                                                  videoSrcURL={url}
                                                  poster={src} />
                                              </div>{" "}

                                              <div className='text-center mt-5'>
                                                <a
                                                  class="btn lesson-plan-btn"
                                                  // href='/findings'
                                                  href="/toddler-talk-assessment-findings"
                                                >
                                                  <span className="lesson-plan-btn-text">
                                                    Go To Findings
                                                  </span>
                                                </a>
                                              </div>

                                            </div>
                                            <div>
                                              <StyledContainer>
                                                <Row className="justify-content-center">
                                                  <Col sm="8" md="6">
                                                    {error && (
                                                      <Alert variant="danger">
                                                        {error}
                                                      </Alert>
                                                    )}
                                                  </Col>
                                                </Row>
                                              </StyledContainer>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6be929f"
                                        data-id="6be929f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-97fbc33 cms-animation-bottom-to-top2 elementor-widget elementor-widget-cms_image_single"
                                            data-id="97fbc33"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container animation-bottom">
                                              <div className="cms-image-single cms-animation-bottom-to-top ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="160"
                                                    height="166"
                                                    src="../images/image-home-3.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-b410ac4 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="b410ac4"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-0b4fe1f"
                                        data-id="0b4fe1f"
                                        data-element_type="column"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PaymentSuccess;
